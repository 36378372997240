#account-page {
  margin-top: 70px;
  margin-bottom: 70px;
  padding: 0 20px;
}

/* Container styling */
.account-container {
  position: relative; /* Ensure that the container is the reference for absolutely positioned elements */
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  margin: 0 auto;
}

.account-section {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 24px;
  overflow: hidden;
}

.section-title {
  margin: 0;
  padding: 16px 24px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  color: #333;
  font-size: 1.2rem;
}

.section-content {
  padding: 0px 24px 24px 24px;
}

/* Profile specific styling */
.profile-info-item > div {
  margin-bottom: 8px;
}

.profile-info-item h4 {
  color: #2c3e50;
  margin-bottom: 8px;
}

.profile-info-item p {
  color: #666;
  margin: 0;
}

.profile-actions-section {
  padding-top: 24px;
  border-top: 1px solid #e9ecef;
}

.account-page-header-container {
  text-align: center;
  margin-bottom: 24px;
}

#account-page-title {
  color: #2c3e50;
  font-size: 1.2rem;
  margin: 0;
}

.account-page-text-input{
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .account-container {
    min-width: auto;
  }
}