#scales-page {
  margin-top: 70px;
}

.scales-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.scales-page-section {
  padding: 20px;
}

.scales-page-header-container {
  text-align: center;
}

.scales-page-text-input {
  margin-bottom: 10px;
} 