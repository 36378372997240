#users-page {
  margin-top: 70px;
}

.users-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  min-width: 650px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.users-page-section {
  padding: 20px;
}

.users-page-header-container {
  text-align: center;
}

.users-page-text-input {
  margin-bottom: 10px;
}

.user-role-section {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.user-role-section h3 {
  margin-top: 0;
  color: #333;
}

.user-role-section h4 {
  color: #666;
  margin: 10px 0;
}

.user-role-section p {
  color: #777;
  margin-bottom: 0;
} 