.group-info-item {
  margin-bottom: 24px;
}

.group-info-item h4 {
  color: #2c3e50;
  margin-bottom: 8px;
}

.group-info-item p {
  color: #666;
  margin: 0;
}

.group-join-create {
  margin-top: 20px;
}

.group-join-section,
.group-create-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.subscription-section {
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid #e9ecef;
}

.subscription-section h3 {
  color: #2c3e50;
  margin-bottom: 16px;
}

.subscription-section h4 {
  color: #666;
  margin: 12px 0;
}

.subscription-section p {
  color: #777;
  margin-bottom: 16px;
}

button {
  background-color: #4a90e2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
}

button:hover {
  background-color: #357abd;
}

.account-page-text-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
} 